import React from "react"
import SanityImage from "gatsby-plugin-sanity-image"
import { graphql } from "gatsby"
import format from "date-fns/format"
import { PortableText } from "@portabletext/react"

import { DefaultLayout } from "../layouts/Default"
import { Pricing } from "../components/Pricing"
import { Modules } from "../components/Modules"
import { Testimonial } from "../components/Testimonial"
import { SEO } from "../components/SEO"
import { Hero } from "../components/Hero"

const CourseSalesPage = ({ data, pageContext }) => {
  const {
    title,
    heroTitle,
    heroPreText,
    description,
    price,
    lastUpdated,
    purchaseLink,
    _rawContent: content,
    whatsInsideDescription,
    whatsInsideList,
    whatsInsideImage,
    testimonials,
  } = data.sanityClass

  return (
    <DefaultLayout>
      <SEO title={`${heroTitle} - ${title}`} description={description} />

      {/* Hero */}
      <Hero
        pretext={heroPreText || "Learn to"}
        title={heroTitle}
        subtitle={description}
      >
        <div className="py-8 flex items-center">
          <a
            href="#pricing"
            className="bg-brand-green rounded-md py-3 px-6 text-white shadow-md font-semibold text-lg"
          >
            Get Started
          </a>
          {price === 0 && (
            <p className="ml-4 text-md text-white font-semibold">It's free!</p>
          )}
        </div>
        <p className="text-white">
          Last Updated: {format(new Date(lastUpdated), "MMM do, yyyy")}
        </p>
      </Hero>

      {/* Intro */}
      <div className="pt-6 pb-10 border-b border-gray-300">
        <div className="container mx-auto px-8 max-w-screen-md prose prose-blue md:prose-xl">
          <PortableText value={content} />
        </div>
      </div>

      {testimonials[0] && (
        <Testimonial
          text={testimonials[0].text}
          author={testimonials[0].name}
        />
      )}

      {/* What's inside */}
      <div className="py-6 pb-10 border-b border-gray-300">
        <div className="container mx-auto px-8 max-w-screen-lg prose prose-blue md:prose-xl">
          <div className="flex flex-col md:flex-row items-center">
            {!whatsInsideImage && <div className="flex-1" />}

            <div className="w-9/12 mr-8">
              <h2>What's Inside?</h2>
              <p>{whatsInsideDescription}</p>

              <p className="mb-0">It covers topics like:</p>

              <ul className="">
                {whatsInsideList.map(item => (
                  <li className="pb-2" key={item}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex-1">
              {whatsInsideImage && <SanityImage {...whatsInsideImage} />}
            </div>
          </div>
        </div>
      </div>

      <div className="border-b border-gray-300 py-16 flex items-center justify-center">
        <a
          href="#pricing"
          className="bg-teal-500 rounded-lg py-4 px-12 text-white shadow-lg font-semibold text-2xl hover:bg-teal-600"
        >
          Join Now
        </a>
      </div>

      <Modules modules={pageContext.modules} />

      {testimonials[1] && (
        <Testimonial
          text={testimonials[1].text}
          author={testimonials[1].name}
        />
      )}

      <div id="pricing">
        <Pricing
          purchaseLink={purchaseLink}
          price={price}
          courseTitle={title}
        />
      </div>

      {testimonials[2] && (
        <Testimonial
          text={testimonials[2].text}
          author={testimonials[2].name}
        />
      )}
    </DefaultLayout>
  )
}

export default CourseSalesPage

export const query = graphql`
  # These fragments come from the gatsby-plugin-sanity-image because gatsby wasn't picking up the
  # fragments that it was exporting.
  # https://github.com/coreyward/gatsby-plugin-sanity-image/blob/master/src/gatsby-node.js
  fragment Image on SanityImage {
    asset {
      _id
    }
    hotspot {
      height
      width
      x
      y
    }
    crop {
      bottom
      left
      right
      top
    }
  }

  fragment ImageWithPreview on SanityImage {
    ...Image
    asset {
      metadata {
        preview: lqip
      }
    }
  }
  query ($_id: String!) {
    sanityClass(_id: { eq: $_id }) {
      _id
      title
      description
      heroTitle
      heroPreText
      price
      lastUpdated
      purchaseLink

      whatsInsideDescription
      whatsInsideList
      whatsInsideImage {
        ...ImageWithPreview
      }

      _rawContent

      testimonials {
        name
        text
      }
    }
  }
`
