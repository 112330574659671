import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const Modules = ({ modules = [] }) => (
  <div className="pb-10 pt-2 border-b border-gray-300">
    <div className="container mx-auto px-8 max-w-screen-lg">
      {modules.map(({ title, lessons }, index) => {
        const lessonsToRender =
          lessons === null || lessons === undefined ? [] : lessons

        return (
          <div className="pt-8" key={title}>
            <div className="flex flex-row items-center  mt-4 ">
              <p className="text-sm text-gray-600 uppercase font-semibold">{`Module ${
                index + 1
              }`}</p>
            </div>
            <p className="font-semibold text-2xl text-gray-800">
              {title || "Lessons"}
            </p>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {lessonsToRender.length === 0 ? (
                <p className="text-xl text-gray-600 italic mt-3">
                  Coming Soon...
                </p>
              ) : null}
              {lessonsToRender.map(({ frontmatter }) => (
                <div className="flex flex-col mt-3" key={frontmatter.title}>
                  <a
                    className="relative flex-grow cursor-pointer overflow-hidden"
                    href="#pricing"
                  >
                    {frontmatter.image ? (
                      <img
                        src={`${frontmatter.image}?image_crop_resized=800x450&amp;image_play_button_size=2x&amp;image_play_button=1&amp;image_play_button_color=7ca1b4e0`}
                        className=""
                        alt={`${frontmatter.title} lesson video screenshot`}
                      />
                    ) : (
                      <StaticImage
                        src="../images/lesson-fallback.png"
                        alt="generic screenshot"
                      />
                    )}
                    <p className="text-gray-800 font-semibold text-lg pt-2 text-sm">
                      {frontmatter.title}
                    </p>
                  </a>
                </div>
              ))}
            </div>
          </div>
        )
      })}
    </div>
  </div>
)
