import React from "react"

export const Toggle = ({
  id,
  uncheckedText,
  checkedText,
  checked,
  onChange,
}) => {
  return (
    <label htmlFor={id} className="flex items-center cursor-pointer">
      <div className="relative">
        <input
          type="checkbox"
          id={id}
          className="sr-only"
          checked={checked}
          onChange={onChange}
        />
        <div className="block bg-gray-200 w-14 h-8 rounded-full" />
        <div
          className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition ${
            checked ? "bg-teal-400 translate-x-full" : "bg-white"
          }`}
        />
      </div>
      <p className="ml-3 text-gray-600 font-medium text-sm">
        {checked ? checkedText : uncheckedText}
      </p>
    </label>
  )
}
